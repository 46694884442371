*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond-below(xl) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond-below(lg) {
    // width < 992?
    font-size: 50%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond-below(md) {
    // width < 768?
    font-size: 37.5%; //1 rem = 6px, 6/16 = 37.5%
  }
}
