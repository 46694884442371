@import "../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";
@import "src/assets/scss/abstracts/mixins";

.formContainer {
  padding: 4rem 6rem;
  margin-top: 6rem;
  width: 702px;

  @include respond-below(lg) {
    width: 480px;
    margin-top: 40px !important;
    padding: 20px 69px;
    margin: auto;
  }

  @include respond-below(sm) {
    width: 100%;
    padding: 20px 16px;
  }
}

.list {
  margin-top: 6.3rem;
  margin-bottom: 4rem;
}

:global {
  .ant-btn.ant-btn-ghost:local(.addBuilderButton) {
    background-color: $color-white;
    color: $color-white;
    border-color: $color-white;
    height: 6rem;
    width: 6rem;

    &:hover {
      border-color: $color-green-4;
    }
  }

  .ant-btn.ant-btn-ghost[disabled]:local(.addBuilderButton) {
    background-color: $color-white;
    color: $color-white;
    border-color: $color-white;
    height: 6rem;
    width: 6rem;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);

    &:hover {
      border-color: $color-grey-5;
    }
  }
}

:global(.ant-btn.ant-btn-primary).removeBuilderButton {
  border-color: $color-green-4;
  background-color: $color-green-4;
  color: $color-green-4;
  background: $color-green-4;
  height: 6rem;
  width: 6rem;

  &:not([disabled]):focus {
    background-color: $color-green-4;
  }

  &:not([disabled]):hover {
    background-color: $color-green-4;
  }
}

:global(.ant-btn .anticon).addBuilderIcon {
  color: $color-green-4;
  font-size: 3rem;
}

:global(.ant-btn[disabled] .anticon).addBuilderIcon {
  color: $color-grey-5;
}

:global(.ant-btn .anticon).removeBuilderIcon {
  color: $color-white;
  font-size: 3rem;
}

.zipTitle:global(.ant-typography) {
  margin-bottom: 20px;
  text-align: center;
}

.zipSubmitButton {
  width: 100%;
}

.linkBtn:global(.ant-btn) {
  font-size: $font-size-default;
  padding: 0 !important;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.collapseIcon {
  color: $color-blue-3 !important;
  font-size: 5.2rem;
  margin-right: 2rem;
}

.collapseTitle {
  display: flex;
  align-items: center;
  font-weight: 700;

  @include respond-below(lg) {
    font-size: $font-size-sm !important;
  }
}

.cardInfo {
  @include respond-below(lg) {
    font-size: $font-size-default !important;
  }
}

:global(.ant-collapse).collapse {
  margin-top: 6.3rem;
  margin-bottom: 8rem;

  @include respond-below(sm) {
    display: flex;
    justify-content: center;
  }
}

.startSearchButon:global(.ant-btn) {
  display: none;
  margin-bottom: 90px;

  @include respond-below(sm) {
    display: block;
  }
}

.zipFormItem {
  width: 28rem;

  &.zipCode {
    @include respond-below(lg) {
      margin-bottom: 20px !important;
    }
  }

  @include respond-below(lg) {
    width: 100%;
  }
}

.listTitle {
  font-size: 24px !important;

  @include respond-below(sm) {
    font-size: 18px !important;
  }
}
