@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

.modal {
  :global(.ant-modal-body) {
    padding: 4rem;
  }

  .modalCancelButton {
    width: 16rem !important;
    border-color: $color-yellow-1 !important;
  }

  .modalButton {
    width: 22rem !important;
  }

  .modalButtonContainer {
    margin-top: 4rem;
  }

  .modalTitle {
    margin-bottom: 2rem;
  }
}

.formItemLabel {
  :global(.ant-col.ant-form-item-label) {
    font-size: $font-size-sm;
    width: 14rem;
    text-align: start;
    margin-right: 1rem;
    font-weight: 600;
  }

  :global(.ant-col.ant-form-item-control) {
    width: 25.6rem;
  }
}

:global(textarea.ant-input).customInputBackground {
  width: 72rem !important;
  height: 12rem !important;
  line-height: 22px !important;
}

:global(.ant-input[disabled]).customInputBackground {
  background-color: $color-white;
  color: $color-blue-6;
}

.providerPictureUpload {
  margin-top: 4rem;
  margin-left: 6rem;
  margin-right: 3rem;
}

.form {
  margin-top: 6.8rem !important;
  white-space: pre-line;
}

:global(.ant-select).customSelectBackground {
  width: 25.6rem !important;

  .ant-select-selection-overflow.ant-select-selection-overflow-item.ant-select-selection-item {
    background: $color-grey-5 !important;
  }
}

.pricingEditor {
  width: 12.6rem !important;
}

.dataLabel:global(.ant-typography) {
  margin-top: 2rem;
  margin-bottom: 0;
  font-size: $font-size-sm;
}

.radioGroup {
  margin-bottom: 4rem;
}
