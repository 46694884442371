@import "../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

.draggableArea {
  max-height: 540px;
  margin-top: 24px;
  overflow: auto;
}

.draggableItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  margin-bottom: 8px;

  & > * {
    margin-right: 8px;
  }
}

.itemText {
  flex: 1;
}

.grabIcon {
  width: 24px;
}

.grabIcon,
.actionElement {
  text-align: center;
}

.actionElement {
  width: 32px;
}

.grabIcon,
.actionElement,
.itemText {
  margin-right: 8px;
}

.itemText,
.actionElement {
  &:global(.locked) {
    color: #506a82;
  }
}

.title {
  font-size: 1rem;
  font-weight: 700;
}
