@import "../../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "assets/scss/abstracts/mixins";
@import "src/assets/scss/abstracts/variables";

.cardContainer {
  padding: 5rem 4rem 5.4rem 4rem;
  width: 80rem;
  margin: auto;
  max-width: 100%;
}

:global(.ant-divider).divider {
  color: $color-blue-3;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

:global(.ant-input).textArea {
  width: 55.5rem;
  height: 18rem;
  border-color: $color-blue-3;
  resize: none;
}

:global(.ant-form-item .ant-select).select {
  width: 25rem;

  @include respond-below(sm) {
    width: 250px;
  }
}

:global(div.ant-typography).details {
  margin-bottom: 1rem;

  font-size: $font-size-lg;
  font-weight: 400;

  @include respond-below(lg) {
    font-size: $font-size-md !important;
    margin-bottom: 0 !important;
  }
}

:global(div.ant-typography).detailsTitle {
  margin-bottom: 1rem;

  font-size: $font-size-lg;
  font-weight: 700;

  @include respond-below(lg) {
    font-size: $font-size-md !important;
    margin-bottom: 0 !important;
  }
}

:global(.ant-typography).title {
  margin-bottom: 5rem;
  font-weight: 700;

  @include respond-below(sm) {
    font-size: $font-size-default !important;
  }
}

.navigatorButton {
  width: 200px !important;
}

:global {
  .ant-upload-hint {
    @include respond-below(lg) {
      font-size: 12px !important;
      font-weight: 400;
    }
  }

  .ant-upload-drag-container {
    @include respond-below(lg) {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
  }

  .ant-upload-text {
    @include respond-below(lg) {
      font-size: $font-size-default !important;
      font-weight: 400;
    }
  }
}

.fileUploadContainer {
  margin-top: 2rem;
}
