@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

.modal {
  width: 58rem;

  :global(.ant-modal-body) {
    padding: 3rem 4rem 3.6rem 4rem;
  }
}

:global(.ant-typography).title {
  margin-bottom: 5rem;
}

.uploadContainer {
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.buttonContainer {
  margin-top: 5rem;
  margin-right: 4rem;
  margin-left: 4rem;
}

:global(.ant-input).textArea {
  width: 50rem;
  height: 11.6rem;
  border-color: $color-grey-border;
  resize: none;
}

:global(.ant-btn.ant-btn-default:not([disabled])).sendButton {
  background: $color-grey-3 !important;
  color: $color-red-6 !important;
  border: 1px solid $color-grey-5 !important;

  &:hover {
    border: 1px solid $color-red-6 !important;
  }
}

.sendButton {
  min-width: fit-content;
  width: 216px;
  height: 40px;
}

.cancelButton {
  width: 170px;
  height: 40px;
}

:global(.ant-btn.ant-btn-default:not([disabled])).uploadButton {
  color: $color-blue-3;
  border: 1px solid $color-blue-3;

  &:hover {
    border: 1px solid $color-blue-2;
  }
}

.description:global(.ant-typography) {
  margin-bottom: 5rem;
}

.noteTite:global(.ant-typography) {
  margin-top: 5rem;
}

.bold {
  font-weight: 700;
}
