@import "../../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

.modal {
  max-width: 100%;

  &:global(.ant-modal) {
    top: 20px;
    height: calc(100% - 20px);
    position: relative;
    @media screen and (max-height: 640px) {
      top: 0;
      height: 100%;
      padding-bottom: 0;
    }
  }

  :global {
    .ant-modal-content {
      height: 100%;

      .ant-modal-body {
        height: 100%;
      }
    }

    .ant-table-tbody {
      td:local(.priceCell) {
        text-align: right !important;
      }
    }
  }
}

.title {
  margin-bottom: 3rem;
}

.buttonContainerRow {
  margin-bottom: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.closeButton {
  margin-top: 6rem;
  width: 18rem;
}

.content {
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;

  thead[class*="ant-table-thead"] th {
    background-color: $color-blue-1 !important;
    color: $color-white !important;
  }
}

.table {
  :global {
    .ant-table {
      color: $color-blue-2;
    }
  }
}

.amountCell {
  padding: 0 !important;
  height: 1px;
}

.nameCell {
  white-space: pre-line;
}

.priceCell {
  background-color: #dadcde !important;
}

.codeCell {
  background-color: $color-grey-3;
}

@-moz-document url-prefix() {
  .amountCell {
    min-height: 1px;
    height: auto;
  }
}

.tabTotal {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 8px;

  span {
    margin-left: 100px;
  }
}
