@import "../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

.order {
  height: 100%;
  padding: 8px 8px;

  &.designOrder {
    padding: 16px 16px;
    border-left: 10px solid;
    border-color: $color-blue-3;
  }

  &.builderOrder {
    padding: 16px 16px;
    border-left: 10px solid;
    border-color: $color-blue-1;
  }
}

td.firstColumn {
  padding: 0 !important;
  height: 1px;
}

th.firstColumn {
  :global(.ant-table-column-title) {
    margin-left: 10px;
  }
}

.table {
  :global {
    .ant-table {
      background: transparent !important;
      border-collapse: collapse;

      .ant-table-column-sort {
        background-color: unset;
      }

      .ant-table-tbody {
        background-color: white !important;

        & > tr > td.ant-table-cell-row-hover {
          background-color: $color-blue-13;
          cursor: pointer;
        }

        .ant-table-row:last-child .ant-table-cell {
          border: none;
        }

        .ant-table-cell {
          font-size: $font-size-xs;
          color: $color-grey-8;
        }
      }

      .ant-table-thead .ant-table-cell {
        border: none;
        font-size: $font-size-xs;
        font-weight: 900;

        background: transparent;

        &::before {
          display: none !important;
        }
      }
    }
  }

  &.ngbs {
    :global {
      .ant-table {
        .ant-table-tbody {
          & > tr > td.ant-table-cell-row-hover {
            background-color: $color-green-1;
          }
        }
      }
    }
  }
}
