.ant-modal {
  .ant-modal-content {
    border-radius: 2rem;
  }

  .ant-modal-header {
    border-radius: 2rem;
    border-bottom: none;
  }
}
