@import "../abstracts/variables";

.ant-form-item-label {
  font-weight: 600;
  font-size: 14;
}

.anticon.anticon-paper-clip {
  color: $color-grey-7;
}

.ant-form-item-control-input .ant-form-item-control-input-content {
  .ant-input,
  .ant-input-number,
  .ant-picker,
  .ant-select .ant-select-selector {
    border-radius: 4px;
  }

  .ant-input-number {
    width: 100%;
    height: 40px;
    border: 1px solid $color-blue-3;
  }

  .ant-select.ant-select-lg,
  .ant-input {
    font-size: $font-size-default;
  }
}

.ant-form-item.ant-row {
  margin-bottom: 10px;
}

::placeholder,
.ant-select-selection-placeholder {
  font-weight: 300;
  color: $color-blue-6 !important;
  font-style: italic;
  font-size: $font-size-default;
}

.ant-form-horizontal
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    display: none;
  }

  &::after {
    content: "*:";
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
  }
}

.ant-form-vertical
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    display: none;
  }

  &::after {
    display: inline-block;
    margin-right: 4px;
    font-size: 14px;
    line-height: 1;
    content: "*";
  }
}
