@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/mixins";
@import "src/assets/scss/abstracts/variables";

.cardContainer {
  padding: 5rem 4.8rem;
  width: 90rem;
  margin: auto;
  max-width: 100%;
  white-space: pre-line;

  @include respond-below(lg) {
    padding: 50px 43px 40px;
  }

  @include respond-below(sm) {
    padding: 36px 24px 60px;
  }

  .buttonLabel:global(.ant-typography) {
    margin-bottom: 0;
    @include respond-below(lg) {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.selectedEngineer {
  border-bottom: 0.5px solid $color-blue-3;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;

  .selectedEngineerData {
    margin-left: 20px;

    :global(.ant-typography) {
      margin-bottom: 0;
    }
  }

  @include respond-below(lg) {
    padding-bottom: 44px;
  }

  @include respond-below(sm) {
    padding-bottom: 22px;
  }
}

.content {
  padding: 6rem 6rem 4rem;

  .selectionTitle:global(.ant-typography) {
    @include respond-below(lg) {
      font-size: 16px;
    }

    @include respond-below(sm) {
      padding: 0 20px;
    }
  }

  @include respond-below(lg) {
    padding: 30px;
    padding-bottom: 0;
  }

  @include respond-below(sm) {
    padding: 28px 0 0;
  }
}

.iconsContainer {
  margin-top: 3rem;
  display: flex;
  justify-content: center;

  @include respond-below(lg) {
    margin-top: 20px;
  }
}

.icon {
  cursor: pointer;
  // max-width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-word;

  &:hover {
    :global(.ant-typography) {
      color: $color-blue-4;
    }

    path {
      stroke: $color-blue-4;
      fill: $color-blue-4;
    }
  }

  svg {
    width: 128px;
    height: auto;
    @include respond-below(lg) {
      width: 80px;
    }
  }
}

.leftIcon {
  margin-right: 9rem;

  @include respond-below(sm) {
    margin-right: 40px;
  }
}

.backButton {
  width: 200px;

  @include respond-below(lg) {
    font-size: 14px;
  }
}
