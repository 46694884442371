@import "../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";
@import "src/assets/scss/abstracts/mixins";

.footer:global(.ant-layout-footer) {
  margin-top: auto;
  padding: 4rem $layout-horizontal-padding-desktop;
  color: $color-white !important;

  h5 {
    color: $color-white !important;
  }

  @include respond-below(xl) {
    padding: $layout-horizontal-padding-tablet;
  }

  @include respond-below(sm) {
    padding: $layout-horizontal-padding-mobile;
  }
}

.footerItem {
  display: inline-block;
  font-size: 12px !important;

  &:not(:last-child) {
    margin-right: 1.6rem;
  }
}

.logo {
  color: $color-white;
  width: 7rem !important;

  @include respond-below(sm) {
    width: 82px !important;
  }
}

:global(.ant-typography a).kbLink {
  color: $color-green-4;

  @include respond-below(sm) {
    font-size: 10px;
  }
}

:global(div.ant-typography).spaceItem {
  margin-top: 4rem;

  @include respond-below(sm) {
    font-size: 10px;
  }
}

.bottomSection {
  margin-top: 5.6rem;

  @include respond-below(sm) {
    margin-top: 23px;
  }
}

.copyright {
  font-size: 12px !important;
}

.statementSection {
  text-align: center;
}

.navLink,
.copyRightDesktop {
  color: $color-white;

  @include respond-below(lg) {
    font-size: 10px;
  }
}

.copyRightDesktop {
  text-align: right;
}

.nextButton {
  color: $color-white;
  text-decoration: underline;
  font-weight: bold;
}

.mobileContatct {
  font-size: 10px;
}

.description {
  font-size: 11px;
}

.contact {
  font-size: $font-size-default !important;
  font-weight: 700;
  color: $color-white !important;
}

.email {
  font-size: 11px !important;
  color: $color-white !important;
}

.footerTitle {
  font-size: $font-size-default !important;
}

.mobileContatctTitle {
  font-size: 12px !important;
}
