@import "../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

.cardTitle {
  padding: 4rem 0;
}

.subTitle {
  padding-bottom: 5rem;
}

.fileInputCard {
  margin-bottom: 2rem;
  padding: 2rem 3rem 2.5rem;

  .fileInputTitle {
    margin-bottom: 2.5rem;
    :global(.anticon-info-circle) {
      margin-left: 11.5px;
      color: $color-grey-7;
    }
  }

  .modifyDemandBtn:global(.ant-btn.ant-btn-primary:not([disabled])) {
    margin-top: 3rem;
    color: $color-red-6;
    background-color: $color-grey-3;
    border-color: $color-grey-5;

    &:hover,
    &:focus {
      background-color: lightgrey !important;
      border-color: $color-grey-5;
      color: $color-red-6;
    }

    &:active {
      background-color: $color-grey-3 !important;
      border-color: $color-grey-5;
      color: $color-red-6;
    }
  }
}
.fileInputCard {
  width: 56rem;
}

.sendDemandBtn {
  margin-top: 3rem;
}

.noFileParag:global(.ant-typography) {
  margin-bottom: 0;
}

.divider {
  border-top: 1px solid #e3e6e8;
  margin: 0 4rem;
}

.finalieDesignBtn {
  margin-top: 2rem;
  margin-bottom: 4rem;
  width: 30rem;
}

.workingTypeSelect {
  &:global(.ant-select:not(.ant-select-customize-input)) {
    :global {
      .ant-select-arrow {
        color: #495057;
      }

      .ant-select-selector {
        border-radius: 5px;
        min-width: 23rem;
        min-height: 36px;
        border: 1px solid $color-grey-border;
      }

      .ant-tag {
        display: flex;
        align-items: center;
        padding-top: 2px;
        padding-bottom: 2px;
        margin-top: 2px;
        margin-bottom: 2px;
      }
    }
  }
}

.otherFileUpload {
  display: flex;
  :global {
    .ant-upload-list-item {
      margin-top: 0;
      color: $color-blue-3;

      .anticon-delete svg {
        color: #009cbd;
      }

      .ant-upload-list-item-info:hover {
        background-color: transparent;
      }
    }

    .ant-upload-list-item-name {
      width: auto;
      flex: none !important;
    }
  }
}

.uploadButton {
  margin-right: 9rem;
}

.fileInputTooltipTitle:global(.ant-typography) {
  font-size: $font-size-xs !important;
  color: $color-grey-9;
}

.fileInputTooltipContent {
  color: $color-grey-8;
  font-size: $font-size-xs;
  white-space: pre-line;
}
