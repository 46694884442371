@import "../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";
@import "src/assets/scss/abstracts/mixins";

:global(.ant-list-item).item {
  padding: 20px;
  margin-bottom: 20px;

  align-items: stretch;
}

.description {
  display: flex;
  flex-direction: column;

  :global {
    .ant-typography,
    .ant-typography + .ant-typography {
      margin: 0;
    }
  }

  @include respond-below(lg) {
    flex-direction: column;
  }
}

.providerMetaData {
  display: flex;

  @include respond-below(sm) {
    flex-direction: column;
  }
}

.providerBasicDetail {
  margin-left: 3.6rem;

  @include respond-below(sm) {
    margin-left: 0;
    margin-top: 34px;
  }
}

.details {
  &.desktopDetail {
    margin-top: 36px;
    margin-bottom: 32px;

    @include respond-below(lg) {
      display: none;
    }
  }

  &.tabletDetail {
    display: none;
    margin-top: 22px;
    margin-bottom: 10px;

    @include respond-below(lg) {
      display: block;
    }
  }
}

.item {
  :global {
    .ant-list-item-action {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;

      li {
        text-align: right;
      }
    }
  }
}

.inActiveText {
  color: $color-inactive !important;
  font-weight: 700;
}

.workText {
  font-weight: 600;

  @include respond-below(lg) {
    font-weight: 400;
    font-size: $font-size-default;
  }

  @include respond-below(sm) {
    font-weight: 600;
    font-size: $font-size-default;
  }
}

.actionButton {
  position: absolute;
  right: 0;
  margin: 3rem;
}
