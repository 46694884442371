@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

.navigationLink {
  margin-bottom: 5.8rem;
  a {
    font-size: $font-size-xs;
    color: $color-blue-4;
  }
}

.dataLabel:global(.ant-typography) {
  margin-bottom: 0;
}

.email {
  margin-right: 8px;
}

.card {
  padding: 2rem;
  margin-top: 4.8rem;
}

.detailsButton {
  margin-right: 2rem;
}

:global(.ant-btn.ant-btn-default:not([disabled])).deleteButton {
  background: $color-grey-3 !important;
  color: $color-red-6 !important;
  border: 1px solid $color-grey-5 !important;
  height: 4rem !important;

  &:hover {
    border: 1px solid $color-red-6 !important;
  }
}

:global(.ant-btn.ant-btn-primary).modalDisableButton {
  background: $color-grey-3 !important;
  color: $color-red-6 !important;
  border: 1px solid $color-grey-5 !important;

  &:hover {
    border: 1px solid $color-red-6 !important;
  }
}

.badge {
  height: 34px;
  width: 134px;
  margin-left: 2rem;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  line-height: 34px;
  margin-bottom: 16px;
  color: $color-grey-4;
  background-color: $color-red-2;
}

.badgeYellow {
  height: 34px;
  width: 235px;
  margin-left: 2rem;
  font-weight: 700;
  font-size: 14px !important;
  text-align: center;
  line-height: 34px;
  margin-bottom: 16px;
  color: $color-grey-4;
  background-color: $color-orange-3;
}
