@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "assets/scss/abstracts/mixins";
@import "assets/scss/abstracts/variables";
.cardContainer {
  padding: 5rem 0;
  width: 80rem;
  margin: auto;
  max-width: 100%;

  @include respond-below(lg) {
    width: 714px;
  }

  @include respond-below(sm) {
    padding: 26px 0;
    white-space: pre-line;
  }

  :global {
    .ant-upload.ant-upload-drag .ant-upload-text {
      font-size: $font-size-default;
    }

    .ant-upload.ant-upload-drag .ant-upload-hint {
      font-size: 12px;
    }
  }
}

.homePhase {
  padding: 7.5rem 0 0 40px;
  :global(.ant-form-item-control-input) {
    min-height: unset;
  }

  @include respond-below(lg) {
    padding-top: 40px;
  }

  @include respond-below(sm) {
    padding-top: 20px;
    padding-left: 16px;
  }
}

.form {
  padding: 0 12.5rem 0 4rem;

  @include respond-below(lg) {
    padding: 0 40px;
  }

  @include respond-below(sm) {
    padding: 0 16px;
  }

  .checkBox {
    :global {
      .ant-form-item-label {
        padding: 0;
      }

      .ant-form-item-control-input {
        min-height: unset;
      }

      .ant-checkbox-group {
        @include respond-below(sm) {
          width: 1px;
        }
      }

      .ant-checkbox-inner {
        border-color: $color-blue-3;
      }
    }
  }

  .peopleCountRow {
    padding-left: 0 !important;
  }

  .realEstateSpecInput {
    width: 250px !important;
  }

  .dragger {
    margin-top: 30px;
  }
}

.radio {
  :global {
    .ant-radio-group {
      @include respond-below(lg) {
        width: 300px;
      }
    }
  }
}

.awaitingRenovationForm {
  padding-top: 7.5rem;

  @include respond-below(lg) {
    padding-top: 40px;
  }

  @include respond-below(sm) {
    padding-top: 20px;
  }
}

.button {
  width: 200px;
}

.label {
  :global {
    .ant-form-item-label {
      white-space: pre-wrap !important;
    }
  }
}
