@import "../../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/mixins";
.timeLine {
  &:global(.ant-timeline) {
    margin-left: 6.5rem;
    padding-right: 200px;

    @include respond-below(lg) {
      padding-right: 0px;
    }

    @include respond-below(sm) {
      padding-right: 0;
    }

    :global(.ant-timeline-item) {
      padding-bottom: 60px;

      @include respond-below(lg) {
        padding-bottom: 20px;
      }

      @include respond-below(sm) {
        padding-bottom: 40px;
      }
    }

    @include respond-below(lg) {
      margin-left: 0;
    }
  }

  .timeLineDetails {
    margin-left: 7.75rem;

    @include respond-below(sm) {
      margin-left: 0;
    }
  }

  .timeLineSubTitle {
    margin-bottom: 20px;
    margin-top: 20px;

    @include respond-below(lg) {
      font-size: $font-size-default;
    }
  }

  .timeLineTitle {
    margin-bottom: 40px;

    @include respond-below(lg) {
      margin-bottom: 0px !important;
      font-size: $font-size-default;
    }
  }

  :global {
    .ant-timeline-item-head-blue,
    .ant-timeline-item-tail {
      border-color: $color-blue-4;
    }

    .ant-timeline-item-head-blue {
      width: 16px;
      height: 16px;
    }

    .ant-timeline-item-tail {
      border-left: 1px solid;
      margin-left: 2.5px;
    }
  }

  .listElement {
    list-style-position: inside;
    text-indent: -1em;
    padding-left: 1em;
  }
}
