@import "../../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

.optiComfortCard {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
  width: 56rem;

  &.fileUploadStatus {
    opacity: 0.3;
  }

  .materialList {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .designWip {
    background-color: #d2f7fe;
    margin: 1rem;

    .clock {
      height: 9.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      &.clockWithModification {
        padding-bottom: 25px;
        height: unset;
      }
    }

    .clockIcon {
      font-size: 6rem;
      color: $color-blue-3;
      margin-right: 14.5px;
    }

    :global {
      .ant-typography {
        margin-bottom: 0;
      }
    }
  }

  .materialListActionBtnContainer {
    margin-bottom: 3rem;

    :global {
      .ant-btn:first-child {
        margin-right: 1rem;
      }
    }
  }

  .fileListElement {
    margin-bottom: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: inline-block;
  }
}

.lastModifiedDate {
  padding: 6.5px 0;
  font-weight: 700;
  margin-bottom: 2.5rem;
  font-style: italic;
  &.engineerModifiedDate {
    background-color: $color-blue-10;
    color: $color-blue-3;
  }

  &.ngbsModifiedDate {
    color: $color-green-4;
    background-color: $color-green-1;
  }
}

.notifyEngineerBtn {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.viewBtn {
  margin-bottom: 3rem;
}
