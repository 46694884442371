@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

.modal {
  max-width: 100%;

  &:global(.ant-modal) {
    top: 20px;
    height: calc(100% - 20px);
    position: relative;
    @media screen and (max-height: 640px) {
      top: 0;
      height: 100%;
      padding-bottom: 0;
    }
  }

  :global {
    .ant-modal-content {
      height: 100%;

      .ant-modal-body {
        height: 100%;
      }
    }
  }
}

.saveButton {
  width: 18rem;
}

.cancelButton {
  width: 16rem;
}

.content {
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
}

.tabTotal {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 8px;
  border-bottom: 1px solid $color-grey-border;

  span {
    margin-left: 100px;
  }
}

.buttonContainerRow {
  margin-bottom: 30px;
  width: 100%;
}

.buttonContainer,
.closeButton {
  margin-top: 3rem;
}

.cancelButton {
  margin-right: 5rem;
}

.tabs {
  :global {
    .ant-tabs-content-holder {
      overflow-y: auto;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $color-blue-1;
    }

    .ant-tabs-tab {
      font-size: $font-size-sm;
      font-weight: 400;
    }

    .ant-tabs-tab-active {
      font-weight: 700;
    }

    .ant-tabs-nav {
      &::before {
        display: none;
      }
    }
  }

  &.engineerTab {
    thead[class*="ant-table-thead"] th {
      background-color: $color-blue-3 !important;
      color: $color-white !important;
    }
  }

  &.builderTab {
    thead[class*="ant-table-thead"] th {
      background-color: $color-blue-1 !important;
      color: $color-white !important;
    }
  }

  &.ngbsTab {
    :global(.ant-tabs-ink-bar) {
      background-color: $color-green-ngbs;
    }
    thead[class*="ant-table-thead"] th {
      background-color: $color-green-ngbs !important;
      color: $color-white !important;
    }
  }

  &.adminTab {
    :global(.ant-tabs-ink-bar) {
      background-color: $color-blue-11;
    }
    thead[class*="ant-table-thead"] th {
      background-color: $color-blue-11 !important;
      color: $color-blue-1 !important;
    }
  }
}

.table {
  :global {
    .ant-table {
      color: $color-blue-1;
    }

    thead[class*="ant-table-thead"] th {
      font-weight: 700;
      height: 54px;
    }
  }
}

.amountCell {
  padding: 0 !important;
  height: 1px;
}

.priceCell {
  background-color: #dadcde !important;
  text-align: right;
}

.codeCell {
  background-color: $color-grey-3;
}

@-moz-document url-prefix() {
  .amountCell {
    min-height: 1px;
    height: auto;
  }
}

.inputContainer {
  display: flex;
  padding-right: 1.2rem;
  align-items: center;
  background-color: #fff8d9;
  padding-left: 8px;
  padding-top: 9px;
  padding-bottom: 9px;
  height: 100%;

  &.buildInputContainer {
    justify-content: center;
  }

  :global {
    .ant-input-number {
      border-radius: 5px;
      margin-right: 10px;
      text-align: right;
      width: 5rem;

      .ant-input-number-handler-wrap {
        display: none;
      }

      .ant-input-number-input {
        text-align: right;
        width: 5rem;
      }
    }
  }
}
