@import "../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

.providerCard {
  padding: 2rem;
  margin-bottom: 4.8rem;
}

:global(.ant-input-number).customNumberInputBackground,
:global(.ant-input).customInputBackground,
:global(.ant-select).customSelectBackground {
  width: 36.1rem !important;
}

:global(textarea.ant-input).customInputBackground {
  width: 66.1rem !important;
}

:global(.ant-input-number).customNumberInputBackground {
  background-color: $color-white;
  border-color: $color-blue-3;
  color: $color-blue-1;
}

:global(.ant-input-number-disabled).customNumberInputBackground {
  background-color: $color-white;
  border-color: $color-grey-border !important;
  color: $color-blue-6;

  &:hover {
    background-color: $color-white;
    border-color: $color-grey-border;
    color: $color-blue-6;
  }
}

:global(.ant-input[disabled]).customInputBackground {
  background-color: $color-white;
  border-color: $color-grey-border;
  color: $color-blue-6;

  &:hover {
    background-color: $color-white;
    border-color: $color-grey-border;
    color: $color-blue-6;
  }
}

:global(.ant-select-disabled).customSelectBackground {
  :global(.ant-select-selector) {
    background-color: $color-white !important;
    border: 1px solid $color-grey-border !important;
    color: $color-blue-6 !important;
  }
}

:global(.ant-select-disabled.ant-select-multiple) {
  :global(.ant-select-selection-item) {
    background-color: $color-white !important;
    border: 1px solid $color-grey-border !important;
    color: $color-blue-6 !important;
  }
}

.formItemLabel {
  :global(.ant-col.ant-form-item-label > label) {
    width: 14rem;
    text-align: start;
    margin-right: 1rem;
    font-size: $font-size-sm;
  }
}

.dataLabel:global(.ant-typography) {
  margin-bottom: 0;
}

.introductionLabel:global(.ant-typography) {
  margin-bottom: 0;
  white-space: pre-line;
}

.providerPictureUpload {
  margin-top: 6rem;
}

.navigationLink {
  margin-bottom: 5.8rem;
  color: $color-blue-4;
}

.userDetails {
  padding: 4rem;
  margin-bottom: 4.8rem;
  margin-top: 4.8rem;
}

.card {
  padding: 2rem;
  margin-bottom: 4.8rem;
}

:global(.ant-input[disabled]).input {
  width: 87.5rem;
  background-color: $color-white;
  border-color: $color-grey-border;
  color: $color-blue-6;
}

:global(.ant-input[disabled]).customInputBackground {
  background-color: $color-white;
  border-color: $color-grey-border;
  color: $color-blue-6;
}

.dataLabel:global(.ant-typography) {
  margin-bottom: 0;
}

.pricingEditor {
  width: 120rem !important;
}

.buttonContainer {
  display: flex;
  margin-top: 2rem;
}

.infoIcon {
  color: $color-grey-7 !important;
  margin-top: 1rem;
  margin-left: 1rem;
  z-index: 100;
}

.fileInputTooltipTitle:global(.ant-typography) {
  font-size: $font-size-xs !important;
  color: $color-grey-9;
}

.fileInputTooltipContent {
  color: $color-grey-8;
  font-size: $font-size-xs;
  white-space: pre-line !important;
}

.upload {
  :global {
    .ant-upload {
      border: none !important;
      background-color: transparent;
    }
  }
}

.selectButton {
  min-width: fit-content;
  font-size: $font-size-xs;
}
