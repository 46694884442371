@import "../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/mixins";
@import "src/assets/scss/abstracts/variables";

.builderColor {
  background: linear-gradient(
    105deg,
    $color-blue-1 75%,
    white calc(75% + 1px)
  ) !important;

  @include respond-below(sm) {
    background: $color-blue-1 !important;
  }
}

.adminColor {
  background: linear-gradient(
    105deg,
    $color-blue-11 75%,
    white calc(75% + 1px)
  ) !important;

  @include respond-below(sm) {
    background: $color-blue-11 !important;
  }
}

.ngbsColor {
  background: linear-gradient(
    105deg,
    $color-green-ngbs 75%,
    white calc(75% + 1px)
  ) !important;

  @include respond-below(sm) {
    background: $color-green-ngbs !important;
  }
}

.container {
  height: 120px;
  background: linear-gradient(105deg, $color-blue-3 75%, white calc(75% + 1px));
  padding: 38px $layout-horizontal-padding-desktop;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title:global(.ant-typography) {
    color: white;
    margin-bottom: 0;
    margin-top: 0;
  }

  .subTitle {
    @include respond-below(sm) {
      font-size: $font-size-xs !important;
    }
  }

  @include respond-below(lg) {
    padding: 60px $layout-horizontal-padding-tablet;
  }

  @include respond-below(sm) {
    padding: 7px $layout-horizontal-padding-mobile;

    height: 60px;
    background: $color-blue-3;
  }

  .pageTitleLogo {
    @include respond-below(sm) {
      display: none;
    }
  }
}
