@import "../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

:global(.ant-btn).button:not(:global(.ant-btn-icon-only)) {
  padding: 4px 15px;
  line-height: 16px;
}

.borderLess {
  border: none;
}

// DEFAULT BUTTON
.button:global(.ant-btn.ant-btn-primary:not([disabled])) {
  &:hover,
  &:focus {
    background-color: $color-yellow-3;
  }

  &:active {
    background-color: $color-yellow-4;
    border-color: $color-yellow-4;
  }
}

.button:global(.ant-btn.ant-btn-default:not([disabled])) {
  border: 1px solid $color-yellow-1;
  color: $color-grey-8;

  &:hover,
  &:focus {
    border-color: $color-yellow-3;
  }

  &:active {
    border-color: $color-yellow-4;
  }
}

//////// CUSTOMER BUTTON

.button.customerBtn:global(.ant-btn.ant-btn-primary:not([disabled])) {
  background-color: $color-blue-4;
  border-color: $color-blue-4;
  color: $color-white;

  &:hover,
  &:focus {
    background-color: $color-blue-7;
    border-color: $color-blue-7;
  }

  &:active {
    background-color: $color-blue-2;
    border-color: $color-blue-2;
  }
}

.button.customerBtn:global(.ant-btn.ant-btn-default:not([disabled])) {
  border: 1px solid $color-blue-4;
  color: $color-blue-4;

  &:hover,
  &:focus {
    border-color: $color-blue-7;
    color: $color-blue-7;
  }

  &:active {
    border-color: $color-blue-2;
    color: $color-blue-2;
  }
}

/////// PROVIDER BUTTON

.button.providerBtn:global(.ant-btn.ant-btn-primary:not([disabled])) {
  border: 1px solid $color-blue-3;
  background-color: $color-blue-3;
  color: $color-white;

  &:hover,
  &:focus {
    background-color: $color-blue-8;
    border-color: $color-blue-8;
  }

  &:active {
    background-color: $color-blue-9;
    border-color: $color-blue-9;
  }
}

.button.providerBtn:global(.ant-btn.ant-btn-default:not([disabled])) {
  border: 1px solid $color-blue-3;
  color: $color-blue-3;

  &:hover,
  &:focus {
    border-color: $color-blue-8;
    color: $color-blue-8;
  }

  &:active {
    border-color: $color-blue-9;
    color: $color-blue-9;
  }
}

/////////// NGBS BUTTON

.button.ngbsBtn:global(.ant-btn.ant-btn-primary:not([disabled])) {
  border: 1px solid $color-green-ngbs;
  background-color: $color-green-ngbs;
  color: $color-white;

  &:hover,
  &:focus {
    background-color: $color-green-ngbs-2;
    border-color: $color-green-ngbs-2;
  }

  &:active {
    background-color: $color-green-ngbs-4;
    border-color: $color-green-ngbs-4;
  }
}

.button.ngbsBtn:global(.ant-btn.ant-btn-default:not([disabled])) {
  border: 1px solid $color-green-ngbs;
  color: $color-green-ngbs;

  &:hover,
  &:focus {
    border-color: $color-green-ngbs-2;
    color: $color-green-ngbs-2;
  }

  &:active {
    border-color: $color-green-ngbs-4;
    color: $color-green-ngbs-4;
  }
}

/////////////// BUILDER BUTTON
.button.builderBtn:global(.ant-btn.ant-btn-primary:not([disabled])) {
  border: 1px solid $color-blue-1;
  background-color: $color-blue-1;
  color: $color-white;

  &:hover,
  &:focus {
    background-color: $color-builder-2;
    border-color: $color-builder-2;
  }

  &:active {
    background-color: $color-builder-4;
    border-color: $color-builder-4;
  }
}

.button.builderBtn:global(.ant-btn.ant-btn-default:not([disabled])) {
  border: 1px solid $color-blue-1;
  color: $color-blue-1;

  &:hover,
  &:focus {
    border-color: $color-builder-2;
    color: $color-builder-2;
  }

  &:active {
    border-color: $color-builder-4;
    color: $color-builder-4;
  }
}

/////// DISABLED BUTTON

.button:global(.ant-btn[disabled]) {
  &,
  &:hover {
    box-shadow: $btn-shadow;
  }
}
