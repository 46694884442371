$layout-horizontal-padding-desktop: 120px;
$layout-horizontal-padding-tablet: 16px;
$layout-horizontal-padding-mobile: 16px;

$btn-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

$navbar-height: 10rem;

// COLORS
$color-black: #000000;

$color-background: #fafafa;

$color-white: #ffffff;
$color-white-2: #f1f3f7;

$color-yellow-1: #ffd200;
$color-yellow-3: #ffe366;
$color-yellow-4: #ffed99;
$color-yellow-5: #fef5cc;

$color-inactive: #f58220;

$color-green-1: #e5f6f0;
$color-green-4: #00a766;

$color-green-ngbs: #008481;
$color-green-ngbs-2: #339e9b;
$color-green-ngbs-3: #5cb1af;
$color-green-ngbs-4: #71d6d1;

$color-builder-2: #515c64;
$color-builder-4: #93999f;

$color-grey-3: #f5f5f5;
$color-grey-4: #636466;
$color-grey-5: #d9d9d9;
$color-grey-6: #bfbfbf;
$color-grey-7: #8c8c8c;
$color-grey-8: #595959;
$color-grey-9: #262626;
$color-grey-border: #d8d8d8;
$color-grey-divider: #e3e5e8;

$color-blue-1: #333f48;
$color-blue-2: #b1bde6;
$color-blue-3: #009cbd;
$color-blue-4: #263978;
$color-blue-5: #04234a;
$color-blue-6: #7d88ae;
$color-blue-7: #647ccd;
$color-blue-8: #00b5dc;
$color-blue-9: #2edaff;
$color-blue-10: #d2f7fe;
$color-blue-11: #abe1fa;
$color-blue-12: #bae7ff;
$color-blue-13: #e6f7ff;

$color-orange-3: #ffd591;

$color-red: #f62328;
$color-red-2: #ffccc7;
$color-red-6: #f5222d;

// FONT
$font-size-xs: 14px; // 14px
$font-size-default: 16px; // 16px
$font-size-sm: 18px; // 18px
$font-size-md: 20px; // 20px
$font-size-lg: 24px; // 24px
$font-size-xl: 30px; // 30px
$font-size-xxl: 32px; // 32px

// BREAKPOINTS
$grid-breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1441px,
);

$btn-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
