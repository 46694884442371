@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/mixins";
@import "src/assets/scss/abstracts/variables";

.modal:global(.ant-modal) {
  &,
  :global(.ant-modal-content) {
    @include respond-below(sm) {
      min-height: 100vh;
      max-width: 100% !important;
      margin: 0 !important;
      top: 0 !important;
      border-radius: 0 !important;
    }
  }
}

.modal {
  max-width: 100%;
  width: 1200px !important;

  .avatar {
    @include respond-below(lg) {
      width: 100px !important;
      height: 100px !important;
    }
  }

  @include respond-below(lg) {
    width: 714px !important;
  }

  :global(.ant-modal-body) {
    padding: 4rem 6rem 14rem 6rem;

    @include respond-below(lg) {
      padding: 40px 40px 60px 40px;
    }

    @include respond-below(sm) {
      padding: 16px 16px 60px;
    }
  }

  .engineerDetailSection {
    :global(.ant-typography) {
      margin-bottom: 0;
    }

    .engineerDetailData {
      @include respond-below(lg) {
        font-size: 16px;
        font-weight: 400;
      }

      @include respond-below(sm) {
        font-weight: 700;
      }

      &.specList {
        @include respond-below(sm) {
          font-weight: 400;
        }
      }
    }

    .introductionText {
      font-weight: 300;
      padding: 15px 0;
      white-space: pre-line;

      @include respond-below(lg) {
        font-size: 16px;
        font-weight: 400;
        padding: 27px 0;
      }

      @include respond-below(sm) {
        padding: 40px 0;
      }
    }
  }

  @include respond-below(sm) {
    margin: 0;
    min-width: 100%;
  }

  .engineerDataTitle:global(.ant-typography) {
    margin-top: 0;
  }

  .priceRangeText {
    @include respond-below(sm) {
      white-space: pre-line;
    }
  }

  .cta {
    @include respond-below(sm) {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .backBtn {
    display: none;

    @include respond-below(sm) {
      display: block;
    }
  }
}

.nameTitle {
  @include respond-below(lg) {
    font-weight: 400 !important;
  }

  @include respond-below(sm) {
    font-weight: 700 !important;
  }
}

.professionData {
  @include respond-below(lg) {
    font-size: 16px !important;
  }
}

.selectedEngineer {
  display: flex;
  padding-bottom: 26px;

  .selectedEngineerData {
    margin-left: 40px;

    @include respond-below(lg) {
      margin-left: 18px;
    }

    @include respond-below(sm) {
      margin-left: 0;
      margin-top: 34px;
    }

    :global(.ant-typography) {
      margin-bottom: 0;
    }
  }

  @include respond-below(sm) {
    flex-direction: column;
  }
}
