@import "../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";
@import "src/assets/scss/abstracts/mixins";

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: $color-blue-1;
  position: relative;

  &:after,
  &:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: $color-blue-1;
  }

  &:after {
    top: auto;
    bottom: -6px;
  }
}
.desktopMenu {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.mobileMenu {
  display: none;
  justify-content: end;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 800px) {
    display: flex;
  }
}

.navBar {
  padding: 0 $layout-horizontal-padding-desktop;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  height: 100%;

  &.engineerNavBar {
    background-color: $color-blue-3;

    .menu {
      background-color: $color-blue-3;
    }
  }

  &.ngbsNavBar {
    background-color: $color-green-ngbs;

    .menu {
      background-color: $color-green-ngbs;
    }
  }

  &.adminNavBar {
    background-color: $color-blue-11;

    .menu {
      background-color: $color-blue-11;
    }
  }

  &.builderNavBar {
    background-color: $color-blue-1;

    .menu {
      background-color: $color-blue-1;
    }
  }

  @include respond-below(xl) {
    padding: 0 $layout-horizontal-padding-tablet;
  }

  @include respond-below(xs) {
    padding: 0 $layout-horizontal-padding-mobile;
  }
}

.logo {
  float: left;
  display: flex;
  align-items: center;
  height: 100%;
}

.menu {
  border: 0 !important;
  justify-content: end;
  min-width: 73rem;

  :global {
    .ant-menu-item a {
      font-weight: 600;
      font-size: 14px;

      &:hover {
        color: $menu-item-color !important;
      }
    }

    .ant-menu-item-selected {
      & a {
        font-weight: 900;
        color: $color-blue-5 !important;
      }

      &::after {
        border-bottom: none !important;
      }
    }

    .ant-menu-item {
      &::after,
      &:hover::after {
        border: none !important;
      }
    }
  }
}

.userName {
  margin-left: 1rem;
  display: inline;
  font-size: $font-size-default !important;
  margin-bottom: 0 !important;

  &.providerUserName {
    color: $color-white !important;
  }
}

.profileMenuPopupButtons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profileMenu {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.knowledgeBaseMenuItem,
.loginMenuItem {
  display: flex !important;
  align-items: center;
}

.knowledgeBaseMenuItemContent,
.loginMenuItemContent {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.knowledgeBaseItemIcon,
.loginMenuItemIcon {
  margin-top: -2px;
}

.loginMenuItemText {
  font-weight: 400;

  @media screen and (min-width: 801px) {
    display: none;
  }

  @include respond-above("xxl") {
    display: inline;
  }
}

.loginMenuItemTooltip {
  @media screen and (max-width: 800px) {
    display: none !important;
  }

  @include respond-above("xxl") {
    display: none !important;
  }
}

.knowledgeBaseMenuItemText {
  color: $color-green-4;

  @media screen and (min-width: 801px) {
    display: none;
  }

  @include respond-above("lg") {
    display: inline;
  }
}

.knowledgeBaseItemIcon {
  display: none;

  @include respond-above("md") {
    display: inline;
  }

  @include respond-above("lg") {
    display: none;
  }
}

.knowledgeBaseMenuItemTooltip {
  @include respond-below("md") {
    display: none !important;
  }

  @include respond-above("lg") {
    display: none !important;
  }
}

.tooltipOverlay {
  :global {
    .ant-tooltip-inner {
      background: $color-blue-5;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 0.4rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.2rem;
      white-space: pre-line;
      text-align: center;
    }

    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: #{$color-blue-5};
    }
  }
}
