@import "../../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/mixins";
@import "src/assets/scss/abstracts/variables";

.modal:global(.ant-modal) {
  &,
  :global(.ant-modal-content) {
    @include respond-below(sm) {
      min-height: 100vh;
      max-width: 100% !important;
      margin: 0 !important;
      top: 0 !important;
      border-radius: 0 !important;
    }
  }
}

.modal {
  max-width: 100%;
  max-height: 70rem;

  .avatar {
    @include respond-below(lg) {
      width: 100px !important;
      height: 100px !important;
    }
  }

  :global(.ant-modal-body) {
    padding: 4rem 6rem 14rem 6rem;

    @include respond-below(lg) {
      padding: 40px 40px 60px 40px;
    }

    @include respond-below(sm) {
      padding: 16px 16px 60px;
    }
  }

  @include respond-below(lg) {
    width: 714px !important;
  }
}

.selectedBuilder {
  display: flex;
  padding-bottom: 26px;

  .selectedBuilderData {
    margin-left: 40px;

    @include respond-below(lg) {
      margin-left: 18px;
    }

    @include respond-below(sm) {
      margin-left: 0;
      margin-top: 34px;
    }

    :global(.ant-typography) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  @include respond-below(sm) {
    flex-direction: column;
  }
}

.introductionText {
  font-weight: 300;
  padding: 15px 0;
  white-space: pre-line;
}

:global {
  .ant-btn.ant-btn-ghost:local(.addBuilderButton) {
    background-color: $color-white;
    color: $color-white;
    border-color: $color-green-4;
    height: 6rem;
    width: 6rem;

    &:hover {
      border-color: $color-green-4;
    }
  }

  .ant-btn.ant-btn-ghost[disabled]:local(.addBuilderButton) {
    background-color: $color-white;
    color: $color-white;
    border-color: $color-white;
    height: 6rem;
    width: 6rem;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);

    &:hover {
      border-color: $color-grey-5;
    }
  }
}

:global(.ant-btn.ant-btn-primary).removeBuilderButton {
  border-color: $color-green-4;
  background-color: $color-green-4;
  color: $color-green-4;
  background: $color-green-4;
  height: 6rem;
  width: 6rem;

  &:not([disabled]):focus {
    background-color: $color-green-4;
  }

  &:not([disabled]):hover {
    background-color: $color-green-4;
  }
}

:global(.ant-btn .anticon).addBuilderIcon {
  color: $color-green-4;
  font-size: 3rem;
}

:global(.ant-btn[disabled] .anticon).addBuilderIcon {
  color: $color-grey-5;
}

:global(.ant-btn .anticon).removeBuilderIcon {
  color: $color-white;
  font-size: 3rem;
}

.builderDataTitle:global(.ant-typography) {
  margin-top: 0;
}

.professionData {
  @include respond-below(lg) {
    font-size: 16px !important;
  }
}

.nameTitle {
  @include respond-below(lg) {
    font-weight: 400 !important;
  }

  @include respond-below(sm) {
    font-weight: 700 !important;
  }
}

.builderDetailSection {
  margin-top: 2rem;
}

.specTitle:global(.ant-typography) {
  margin-bottom: 0;
}
