@import "../../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";
@import "src/assets/scss/abstracts/mixins";

.container {
  width: 120rem;
  margin: auto;
  max-width: 100%;
}

.cardContainer {
  padding: 5rem 5rem 24rem 5rem;
  width: 120rem;
  margin: auto;
  max-width: 100%;

  @include respond-below(sm) {
    padding: 26px 17px 107px 27px;
  }
}

:global(.ant-typography).mainTitle {
  margin-bottom: 4rem;

  @include respond-below(sm) {
    font-size: $font-size-default !important;
    font-weight: 700;
  }
}

:global(.ant-form).form {
  margin-top: 6rem;
}

.info {
  text-align: center;
}

:global(div.ant-typography).details {
  margin-bottom: 1rem;
  font-size: $font-size-lg !important;
  font-weight: 400;

  @include respond-below(lg) {
    font-size: $font-size-md !important;
  }
}

:global(div.ant-typography).projectCode {
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: $font-size-lg !important;
  font-weight: 400;

  @include respond-below(lg) {
    font-size: $font-size-md !important;
    margin-top: 0 !important;
    margin-bottom: 20px;
  }
}

:global(div.ant-typography).detailsTitle {
  margin-bottom: 1rem;
  font-size: $font-size-lg !important;
  font-weight: 700;

  @include respond-below(lg) {
    font-size: $font-size-md !important;
  }
}

:global(div.ant-typography).projectCodeTitle {
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: $font-size-lg !important;
  font-weight: 700;

  @include respond-below(lg) {
    font-size: $font-size-md !important;
    margin-top: 8px !important;
  }
}

:global(.ant-typography).title {
  margin-bottom: 2rem !important;
  margin-top: 4rem !important;
  font-size: $font-size-lg;
  font-weight: 700;

  @include respond-below(lg) {
    font-size: $font-size-md !important;
  }
}

:global(.ant-typography).subTitle {
  margin-bottom: 2rem !important;
  margin-top: 4rem !important;
  font-size: $font-size-md;

  @include respond-below(lg) {
    font-size: $font-size-default !important;
  }
}

.submitButton {
  margin-top: 6px;
  margin-bottom: 2.6rem;
  width: 35.1rem !important;
}

.checkBoxGroup:global(.ant-form) {
  padding: 4rem 0 6rem 0;

  @include respond-below(lg) {
    padding: 24px 0 44px;
  }

  @include respond-below(sm) {
    padding: 20px 0 40px;
  }

  :global {
    .ant-form-item,
    .ant-form-item-control-input-content .ant-typography {
      margin-bottom: 0;

      @include respond-below(lg) {
        font-size: 12px;
      }
    }

    .ant-form-item-control-input {
      min-height: unset;
    }

    .ant-checkbox-inner {
      border: 1px solid $color-green-4 !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $color-green-4 !important;
    }
  }
}

:global(.ant-typography).contactData {
  font-weight: 300 !important;
  font-size: $font-size-md;

  @include respond-below(sm) {
    font-size: $font-size-default !important;
    line-height: 32px;
  }
}

:global(.ant-typography).contactDataTitle {
  font-size: $font-size-md;

  @include respond-below(sm) {
    font-size: $font-size-default !important;
    margin-bottom: 0 !important;
    line-height: 32px;
  }
}

.contactNote {
  margin-top: 2rem;
}

:global(.ant-typography).noteText {
  font-weight: 300 !important;
  white-space: pre-line;
  font-size: $font-size-md;

  @include respond-below(sm) {
    font-size: $font-size-default !important;
  }
}

:global(.ant-typography).checkboxText {
  font-weight: 400 !important;
  font-size: $font-size-default;

  @include respond-below(lg) {
    font-size: 12px !important;
  }
}
