@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";
@import "src/assets/scss/abstracts/mixins";

.container {
  width: 100rem;
  margin: auto;
  max-width: 100%;
}

.card {
  padding: 50px 45px 215px;

  .selectedEngineerTitle:global(.ant-typography) {
    margin-top: 40px;
    margin-bottom: 8px;

    @include respond-below(lg) {
      margin-top: 60px;
    }

    @include respond-below(sm) {
      margin-top: 40px;
      font-size: 16px;
    }
  }

  @include respond-below(lg) {
    padding: 50px 50px 90px;
  }

  @include respond-below(sm) {
    padding: 26px 26px 70px;
  }
}

.descriptions:global(.ant-descriptions-bordered) {
  :global {
    .ant-descriptions-row {
      border: none;
    }
    .ant-descriptions-item-label {
      background-color: transparent;
      border: none;
      padding: 0;

      .ant-typography {
        margin-bottom: 8px;
        display: inline-block;

        &:after {
          content: ":";
        }

        @include respond-below(lg) {
          font-size: $font-size-default;
          font-weight: 700;
        }

        @include respond-below(sm) {
          margin-bottom: 0;
          font-weight: 600;
        }
      }
    }

    .ant-descriptions-view {
      border: none;
    }

    .ant-descriptions-item-content {
      padding: 0;
      margin-bottom: 8px;
      font-size: $font-size-md;
      font-weight: 300;

      @include respond-below(lg) {
        font-size: $font-size-default;
      }
    }

    .ant-descriptions-row {
      padding-bottom: 100px;
    }
  }
}

.info:global(.ant-typography) {
  text-align: center;
  margin-bottom: 20px;
}

.title {
  margin-top: 40px !important;
  margin-bottom: 20px !important;

  @include respond-below(sm) {
    margin-top: 36px !important;
    margin-bottom: 28px !important;
  }
}

.selectedEngineer {
  display: flex;

  .selectedEngineerData {
    margin-left: 20px;

    :global(.ant-typography) {
      margin-bottom: 0;
    }
  }
}

.submitButton {
  margin-bottom: 26px;
  width: 350px;
}

.checkBoxGroup:global(.ant-form) {
  padding: 4rem 0 6rem 0;

  @include respond-below(lg) {
    padding: 24px 0 44px;
  }

  @include respond-below(sm) {
    padding: 20px 0 40px;
  }

  :global {
    .ant-form-item,
    .ant-form-item-control-input-content .ant-typography {
      margin-bottom: 0;

      @include respond-below(lg) {
        font-size: 12px;
      }
    }

    .ant-form-item-control-input {
      min-height: unset;
    }
  }
}

.noteText {
  font-weight: 300;
}

.fileText,
.noteText {
  white-space: pre-line;
  @include respond-below(lg) {
    font-size: $font-size-default;
  }
}

.fileText {
  font-weight: 600;

  @include respond-below(lg) {
    font-weight: 700;
  }

  @include respond-below(sm) {
    font-size: $font-size-md;
    font-weight: 600;
  }
}
