@import "../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "assets/scss/abstracts/mixins";

:global(.ant-typography).pageTitle {
  margin-bottom: 3rem;
  text-transform: uppercase;
  white-space: pre-line;
}

.content {
  margin-top: 7.2rem;

  @include respond-below(lg) {
    margin-top: 50px;
  }

  @include respond-below(sm) {
    margin-top: 40px;
  }
}

:global(.ant-steps).steps {
  max-width: 30%;
  margin: auto;

  @include respond-below(lg) {
    max-width: 58%;
  }

  @include respond-below(md) {
    max-width: 100%;
  }

  :global {
    .ant-steps-item-title {
      padding-right: 0px;
    }

    .ant-steps-item {
      padding-left: 8px !important;
    }
  }
}
