@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

.card {
  padding: 4rem;

  .icon {
    font-size: 54px;
    color: $color-green-4;
  }

  .readyParag {
    margin-bottom: 0;
    text-align: center;

    &:last-child {
      margin-bottom: 5rem;
    }
  }

  .cardTitle {
    margin-bottom: 4rem;
  }

  .subTitle {
    margin-bottom: 2.5rem;
  }

  .readySection {
    .jumbotron {
      margin-bottom: 2rem;
    }
  }

  .jumbotron {
    margin: 0;

    :global(.ant-btn:first-child) {
      margin-right: 1rem;
    }
  }

  .orderBtn {
    margin-top: 2rem;
  }

  .orderDate {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  .divider {
    margin: 2rem 0;
  }

  .readyBtn {
    width: 40rem;
  }
}

.materialOrderConfirmModal {
  :global {
    .ant-modal-body {
      padding: 4rem;
    }
  }
}
