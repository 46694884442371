@import "../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "assets/scss/abstracts/variables";
@import "assets/scss/abstracts/mixins";

.alertModal:global(.ant-modal) {
  &,
  :global(.ant-modal-content) {
    @include respond-below(sm) {
      min-height: 100vh;
      max-width: 100% !important;
      margin: 0 !important;
      top: 0 !important;
      border-radius: 0 !important;
    }
  }
}

.cardTitle:global(.ant-typography) {
  margin-bottom: 6.5rem;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3.5rem 0;

  .title {
    white-space: pre-line;

    @include respond-below(sm) {
      font-size: $font-size-default;
    }
  }

  .description {
    text-align: center;
    white-space: pre-line;
  }
}

.homeButton {
  margin-top: 24px;
  min-width: 20rem;
}

.secondaryButton {
  margin-top: 16px;
}

.icon {
  font-size: 6rem;
  color: $color-green-4 !important;
  margin-bottom: 2.5rem;
}
