@import "../../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

.layoutFileList {
  overflow: hidden;
  .layoutFileListElement {
    cursor: pointer;
    display: block;
    padding: 5px;

    text-overflow: ellipsis;
    max-width: 100%;

    overflow: hidden;
    // width: 100%;

    &:last-child {
      margin-bottom: 1rem;
    }

    :global(.anticon-delete) {
      opacity: 0;
      margin-left: 5px;
    }
    &:hover {
      background-color: transparent;
      transition: background-color 200ms linear;

      :global(.anticon-delete) {
        opacity: 1;
        transition: visibility 0.3s linear, opacity 0.3s linear;
      }
    }
  }
}

.fileElementEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.fileInputTooltipTitle:global(.ant-typography) {
  font-size: $font-size-xs !important;
  color: $color-grey-9;
}

.fileInputTooltipContent {
  color: $color-grey-8;
  font-size: $font-size-xs;
  white-space: pre-line;

  .floorPlanTooltipList {
    list-style-position: inside;
    line-height: 25px;
    text-indent: -1.5em;
    padding-left: 1.5em;
  }
}

.popoverIcon {
  margin-left: 11px;
  color: $color-grey-7 !important;
  font-size: 21px;
}

.layoutIcon {
  margin-right: 5px;
}
