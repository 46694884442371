@import "../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";
@import "src/assets/scss/abstracts/mixins";

.formContainer {
  padding: 4rem 6rem;
  margin-top: 6rem;
  width: 702px;

  @include respond-below(lg) {
    width: 420px;
    margin-top: 40px;
    padding: 20px 69px;
  }

  @include respond-below(sm) {
    width: 100%;
    padding: 20px 16px;
  }
}

.zipTitle:global(.ant-typography) {
  margin-bottom: 20px;
  @include respond-below(sm) {
    text-align: center;
  }
}

.zipFormItem {
  width: 28rem;

  &.zipCode {
    @include respond-below(lg) {
      margin-bottom: 20px !important;
    }
  }

  @include respond-below(lg) {
    width: 100%;
  }
}

.zipSubmitButton {
  width: 100%;
}

.collapseIcon {
  color: $color-blue-3 !important;
  font-size: 6rem;
  margin-right: 2rem;
}

.collapseTitle {
  display: flex;
  align-items: center;
  font-weight: 700;

  @include respond-below(lg) {
    font-size: $font-size-sm !important;
  }
}

.providerItemDesc {
  margin-top: 10px;

  :global(.ant-typography) {
    margin: 0;
  }
}

:global(.ant-collapse).collapse {
  margin-top: 63px;
  margin-bottom: 40px;

  @include respond-below(lg) {
    margin-top: 42px;
  }

  @include respond-below(sm) {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}
:global(.ant-typography).descriptionText {
  margin-bottom: 0;
  font-weight: 600;

  @include respond-below(lg) {
    font-size: $font-size-default !important;
  }

  @include respond-below(sm) {
    font-size: $font-size-default !important;
    font-weight: 400;
  }
}

.linkBtn:global(.ant-btn) {
  font-size: $font-size-default;
  padding: 0 !important;
  display: flex;
  align-items: center;
  font-weight: 400;
}

.engineerBoldTitle:global(.ant-typography) {
  font-weight: 800;
  margin-bottom: 40px;

  @include respond-below(lg) {
    margin-bottom: 20px;
  }
}

.startSearchButon:global(.ant-btn) {
  display: none;
  margin-bottom: 90px;

  @include respond-below(sm) {
    display: block;
  }
}

.providerItemText {
  @include respond-below(lg) {
    font-size: $font-size-default !important;
  }
}
