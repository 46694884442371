@import "../../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";
@import "src/assets/scss/abstracts/mixins";

.container {
  margin-top: 6rem;
  margin-bottom: 2rem;
  padding: 4rem;
  background-color: $color-white-2 !important;

  @include respond-below(sm) {
    padding: 16px;
  }
}

.wizardContainer {
  margin-bottom: 2rem;
  padding: 0rem;
  background-color: $color-white;
  box-shadow: none;
  border: none;
}

.selectedCardContainer {
  padding: 1rem;
  height: 11.5rem;
  position: relative;

  &.refusedCardContainer {
    height: 17.1rem;
    display: flex;
    flex-direction: column;
  }

  @include respond-below(lg) {
    width: 296px;
    margin: auto;
  }

  @include respond-below(sm) {
    height: 19.5rem;
    width: auto;
  }
}

.emptyCard {
  padding: 1rem;
  height: 11.5rem;
  padding-right: 5.5rem;
  box-shadow: none;
  border: dashed;
  border-color: #bfbfbf;
  border-width: 1px;
  box-sizing: border-box;

  @include respond-below(lg) {
    width: 296px;
    margin: auto;
  }

  @include respond-below(sm) {
    height: 19.5rem;
    width: auto;
    padding-right: 10px;
  }
}

.emptyCardInnerCard {
  background-color: $color-grey-3;
  height: 9.2rem;
  width: 100%;

  @include respond-below(sm) {
    height: 17rem;
  }
}

.title {
  margin-bottom: 2rem;
}

:global(.ant-typography).wizardTitle {
  font-size: $font-size-lg !important;

  @include respond-below(sm) {
    font-size: $font-size-md !important;
  }
}

:global(.ant-avatar.ant-avatar-icon).titleIcon {
  background-color: $color-green-4;
  margin-right: 1rem;
  color: $color-white;

  @include respond-below(lg) {
    margin-right: 0 !important;
  }
}

:global {
  .ant-btn.ant-btn-ghost:local(.closeIcon) {
    box-shadow: none;
    border: none;
    position: absolute;
    padding-top: 14px;
    padding-right: 14px;
    top: 0;
    right: 0;
  }
}

:global(div.ant-typography).emptyNote {
  padding-left: 16px;
  margin-top: 2rem;
  margin-bottom: 0;
  color: $color-blue-6;
  font-size: $font-size-lg;

  @include respond-below(lg) {
    font-size: $font-size-md !important;
    font-weight: 400;
    white-space: pre-line;
    text-align: center;
  }
}

:global(div.ant-typography).details {
  margin: 0rem;
  font-size: $font-size-default;
}

:global(div.ant-typography).titleText {
  line-break: auto;
}

:global(h4.ant-typography).name {
  margin: 0rem;
  line-break: auto;
}

.nextButton {
  @include respond-below(sm) {
    height: 64px !important;
  }
}

.declined {
  background-color: #fff1f0;
  color: #cf1322;
  height: 4.5rem;
  font-size: 18px;
  font-weight: 700;
  margin-top: auto;
  div {
    display: flex;
    align-items: center;
  }

  svg {
    margin-right: 11px;
    font-size: 22px;
  }
}
