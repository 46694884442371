@import "../abstracts/variables";

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: $color-blue-3;
  border-color: $color-blue-3 !important;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $color-grey-6;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $color-blue-3;
}

.ant-steps .ant-steps-item-title::after {
  height: 2px !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  border: 2px solid $color-blue-3;
}

.ant-steps .ant-steps-item-icon {
  border: 2px solid $color-grey-6;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $color-blue-3;
}
