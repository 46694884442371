@import "../../../../../assets/scss/abstracts/variables";
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

$primary-color: $color-blue-3;

// layout
$layout-footer-background: $color-blue-1;
$layout-header-background: $color-white;
$layout-header-padding: 0;
$layout-header-height: $navbar-height;

// menu
$menu-bg: $layout-header-background;
$menu-item-color: $color-blue-5;
$menu-item-active-bg: $layout-header-background;
$menu-item-padding-horizontal: 1.6rem;

// button
$btn-primary-color: $color-blue-1;
$btn-primary-bg: $color-yellow-1;
$btn-font-weight: 600;
$btn-border-radius-base: 5px;
$btn-shadow: $btn-shadow;
$btn-primary-shadow: $btn-shadow;
$btn-font-size-lg: $font-size-default;

// form
$label-color: $color-blue-1;
$form-item-label-font-size: $font-size-xs;

// input
$input-placeholder-color: $color-blue-6;
$input-height-base: 40px;
$input-border-color: $color-blue-3;

// select
$select-border-color: $color-blue-3;

// font
$font-family: "Nunito";
$text-color: $color-blue-1;
$heading-color: $color-blue-1;

$font-size-base: $font-size-default;

$heading-1-size: $font-size-xxl;
$heading-2-size: $font-size-xl;
$heading-3-size: $font-size-lg;
$heading-4-size: $font-size-md;
$heading-5-size: $font-size-sm;

// timeline
$timeline-color: $color-blue-4;
$timeline-dot-color: $color-blue-4;

@import "src/assets/scss/abstracts/variables";

:global(.ant-list-item).item {
  padding: 2rem;
  align-items: stretch;

  margin-bottom: 4rem;

  :global {
    .ant-list-item-action {
      margin-top: 3rem;

      & > li {
        padding: 0;
      }
    }

    .ant-list-item-meta-avatar {
      margin-right: 3.6rem;
    }

    .ant-list-item-meta-title {
      font-weight: 700;
      font-size: $font-size-md;
    }
  }
}

.detailButton {
  width: 20rem !important;
}

.descriptions {
  :global {
    .ant-descriptions-item-label .ant-typography {
      margin-bottom: 0;
    }

    .ant-descriptions-item {
      padding-bottom: 0;
    }
  }
}

.title {
  :global {
    .ant-typography,
    .ant-typography + .ant-typography {
      margin: 0;
    }
  }
}

.item {
  :global {
    .ant-list-item-action {
      display: flex;
      justify-content: space-between;

      li {
        text-align: right;
      }
    }
  }
}

.searchInput:global(.ant-input-affix-wrapper) {
  width: 25rem;
  margin: 2.4rem 0 4rem;
  border-radius: 5px;
}

.noData {
  margin: 20rem 0;
}

.projectType {
  text-transform: lowercase;

  &::first-letter {
    text-transform: capitalize;
  }
}
